import CardPlan from "@components/CardPlan"
import { getBestPlan, getPeriodFromPlan, getPriceFromPlan } from "@utils/plans"
import { Col, Row } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const UserPlan = (props) => {
  const {
    handlePageSelected,
    registerFormValues,
    plans,
    isChangePlan,
    setCode,
    setCodePurchase,
    isPurchasePlan,
    isUserPlanModal,
  } = props

  const isMobile = useMedia({ maxWidth: 767 })

  const cardPlanRender = (isDesktop) =>
    _map(plans, (plan) => {
      const { interval, amount, code, name } = plan

      const render = (
        <CardPlan
          name={name}
          period={getPeriodFromPlan(interval)}
          price={getPriceFromPlan(amount)}
          registerFormValues={registerFormValues}
          best={getBestPlan(code)}
          plan={plan}
          handlePageSelected={handlePageSelected}
          isChangePlan={isChangePlan}
          setCode={() => setCode(code)}
          setCodePurchase={() => setCodePurchase(plan)}
          isPurchasePlan={isPurchasePlan}
          isSmall={true}
        />
      )
      return isDesktop ? (
        render
      ) : (
        <Col md={12} lg={12} key={plan.code}>
          {render}
        </Col>
      )
    })

  if (isMobile) {
    return (
      <div className={cx("mt32 pb32 userPlaMobile ", styles.userPlan)}>
        <Row gutter={[24, 24]} className="items-center justify-center">
          {cardPlanRender()}
        </Row>
      </div>
    )
  }

  return (
    <div
      className={
        isUserPlanModal ? styles.modalContainer : styles.containerDesktop
      }
    >
      <Row>
        <div className={cx("h-100  center ")}>
          <Row align="center" className="h-100 items-center">
            <div
              className={
                isUserPlanModal
                  ? cx("w-100", styles.userPlan)
                  : cx("mt24 w-100", styles.userPlan)
              }
            >
              <Row gutter={[24, 24]} className="items-center justify-center">
                {cardPlanRender()}
              </Row>
            </div>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default UserPlan
