
const tasks = {}

export const startTask = (taskName: string, job: any, interval: number = 10000) => {
  tasks[taskName] = setInterval(() => {
    job()
  }, interval);
}

export const stopTask = (taskName: string) => {
  clearInterval(tasks[taskName]);
  tasks[taskName] = null
}

export const stopAllPixTasks = () => {
  Object.keys(tasks).forEach((taskName) => {
    if (taskName && taskName.startsWith('pix')) {
      clearInterval(tasks[taskName]);
      tasks[taskName] = null
    }
  })
}

export const getTask = (taskName: string) => tasks[taskName]