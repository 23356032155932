import translate from "@src/i18n"
import React from "react"
import Lottie from "react-lottie"
const animationData = require("./success.json")

function SuccessAnimation({ width = 250, height = 250 }) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className="tc mb32">
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={false}
        isPaused={false}
      />
      <br />
      <h1 className="scorpion-main">{translate("pixSuccessMessage")}</h1>
    </div>
  )
}

export default SuccessAnimation
