import React, { useEffect } from "react"
import { useTimer } from "react-timer-hook"
import styles from "./styles.less"
import cx from "classnames"

function PixExpirationTimer({ expiryTimestamp, onExpire = () => {} }) {
  const {
    seconds,
    minutes,
    // hours,
    // days,
    // isRunning,
    // start,
    // pause,
    // resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: onExpire })

  useEffect(() => {
    if (expiryTimestamp) {
      restart(expiryTimestamp)
    }
  }, [expiryTimestamp])

  return (
    <div className={cx("tc pix-timer")}>
      <div className={styles.pixExpirationTimer}>
        <span>{minutes.toString().padStart(2, "0")}</span>:
        <span>{seconds.toString().padStart(2, "0")}</span>
      </div>
    </div>
  )
}

export default PixExpirationTimer
